import { createAction, props } from '@ngrx/store';
import { Company } from 'src/types';

// eslint-disable-next-line no-shadow
export const enum CompanyActions {
  LoadCompanies = '[COMPANY] Load Companies',
  LoadCompaniesSuccess = '[COMPANY] Load Companies Success',
  LoadCompaniesFailure = '[COMPANY] Load Companies Failure',
  CreateCompany = '[COMPANY] Create Company',
  CreateCompanySuccess = '[COMPANY] Create Company Success',
  CreateCompanyFailure = '[COMPANY] Create Company Failure',
  UpdateCompany = '[COMPANY] Update Company',
  UpdateCompanySuccess = '[COMPANY] Update Company Success',
  UpdateCompanyFailure = '[COMPANY] Update Company Failure',
  DeleteCompany = '[COMPANY] Delete Company',
  DeleteCompanySuccess = '[COMPANY] Delete Company Success',
  DeleteCompanyFailure = '[COMPANY] Delete Company Failure',
  OpenDeleteCompanyPrompt = '[COMPANY] Open Delete Company Prompt',
  SetSearchFilter = '[COMPANY] Set Search Filter',
  ClearSearchFilter = '[COMPANY] Clear Search Filter',
}

/** Load companies */
export const loadCompanies = createAction(CompanyActions.LoadCompanies);
export const loadCompaniesSuccess = createAction(CompanyActions.LoadCompaniesSuccess, props<{ companies: any[] }>());
export const loadCompaniesFailure = createAction(CompanyActions.LoadCompaniesFailure, props<{ error: any }>());

/** Create company */
export const createCompany = createAction(CompanyActions.CreateCompany, props<{ company: any }>());
export const createCompanySuccess = createAction(CompanyActions.CreateCompanySuccess, props<{ response: any }>());
export const createCompanyFailure = createAction(CompanyActions.CreateCompanyFailure, props<{ error: any }>());

/** Update company */
export const updateCompany = createAction(CompanyActions.UpdateCompany, props<{ company: any }>());
export const updateCompanySuccess = createAction(CompanyActions.UpdateCompanySuccess, props<{ response: any }>());
export const updateCompanyFailure = createAction(CompanyActions.UpdateCompanyFailure, props<{ error: any }>());

/** Delete company */
export const deleteCompany = createAction(CompanyActions.DeleteCompany, props<{ uuid: string }>());
export const deleteCompanySuccess = createAction(CompanyActions.DeleteCompanySuccess, props<{ response: any }>());
export const deleteCompanyFailure = createAction(CompanyActions.DeleteCompanyFailure, props<{ error: any }>());

/** Open Delete Company Prompt */
export const openDeleteCompanyPrompt = createAction(CompanyActions.OpenDeleteCompanyPrompt, props<Company>());

/** Search filter */
export const setSearchFilter = createAction(CompanyActions.SetSearchFilter, props<{ searchValue: string }>());
export const clearSearchFilter = createAction(CompanyActions.ClearSearchFilter);
